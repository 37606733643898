<template>
  <div v-if="isVisible">
    <div class="popup-backdrop"></div>
    <div class="popup border shadow">
      <div class="row">
        <div>
            <span class="signature-popup-close-pos cursor-pointer"
                  @click="closePopup()"><strong>X<i class="bi bi-x-lg"></i></strong></span>
        </div>
      </div>
      <div class="row mt-4">
        <p class="popup-content">{{ Block_Content }}</p>
      </div>
      <br/>
      <div class="row">
        <div>
          <button type="button" class="button-mini-warning-cus"
                  @click="onCancelButtonClick">{{ Block_Cancel_Button }}
          </button>
          <button type="button" class="button-mini-warning-cus bg-danger text-white"
                  @click="onConfirmButtonClick">{{ Block_Delete_Button }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.popup {
  position: fixed;
  top: 40%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  margin: 0 auto;
  width: 330px;
  height: 160px;

  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: .3rem;
  border-color: #D7E2FE !important;
}
</style>

<script>
import { mapActions } from "vuex";
import emitter from '../../events/emitter.js';

export default {
  data() {
    return {
      Block_Content: "Souhaitez-vous vraiment supprimer votre espace personnel ?",
      Block_Cancel_Button: "Annuler",
      Block_Delete_Button: "Supprimer",
      isVisible: false
    }
  },
  created() {
    emitter.on('show-confirm-delete-account', () => {
      this.isVisible = true;
    });
    emitter.on('hide-confirm-delete-account', () => {
      this.isVisible = false;
    });
  },
  methods: {
    ...mapActions('client', ['deactivateClient']),
    closePopup() {
      emitter.emit('hide-confirm-delete-account');
    },
    onCancelButtonClick() {
      this.closePopup();
    },
    /**
     * 
     * @param {*} event 
     */
    onConfirmButtonClick(event) {
      const target = event.target;
      target.disabled = true;
      this.deactivateClient().then(() => {
        this.closePopup();
      }).catch((error) => {
        console.error('onConfirmButtonClick', error);
      }).finally(() => {
        target.disabled = false;
      });
    }
  }
}
</script>
