<template>
    <MainLayout
    :content_title="Block_Title">
        <template v-slot:page-sub-title>
            <div class="row">
                <div class="col-12 col-xl-11 offset-xl-1 pb-3 text-start">
                    <h4 class="content-blocks-title"><img src="@/assets/images/profil_bl.svg" class="px-1 mon-profil-height-icon">  <strong>{{fullName}}</strong>
                    </h4>
                </div>
            </div>
        </template>
        <template v-slot:default>
            <!-- first content component -->
            <BlckMyPreferences />
            <!-- end first component -->
        </template>
    </MainLayout>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import MainLayout from "../layouts/main.layout";
import BlckMyPreferences from "../components/Mon-Profil/BlckMyPreferences.vue"

export default {
  page: {
    title: "Home",
    meta: [{ name: "Home page"}],
  },
  components: {
    MainLayout,
    BlckMyPreferences,
  },
  setup() {
    const store = useStore();

    const fullName = computed(() => store.getters[`getUserFullName`]);

    return {
      fullName
    }
  },
  data() {
    return {
      Block_Title : "MON PROFIL",
    };
  },
  methods: {
  },
};
</script>
