<template>
    <div class="coordonnees-emprunteur text-start">
        <div class="p-3">
            <span class="notification-new-doc"><strong>{{Title}}</strong></span><span class="arrow"/>
        </div>
        <div class="row px-3">
            <div class="col-12">
                <p
                    :class='[
                        "support-contact-infos-content-no-margin infos-perso px-3 pt-2",
                        dataLoading ? "field-loading" : ""
                    ]'>{{Client.lastname ?? 'Nom'}}</p>
            </div>
        </div>
        <div class="row px-3">
            <div class="col-12">
                <p
                    :class='[
                        "support-contact-infos-content-no-margin infos-perso px-3 pt-2",
                        dataLoading ? "field-loading" : ""
                    ]'>{{Client.firstname ?? 'Prénom'}}</p>
            </div>
        </div>
        <div class="row px-3">
            <div class="col-12">
                <p
                    :class='[
                        "support-contact-infos-content-no-margin infos-perso px-3 pt-2",
                        dataLoading ? "field-loading" : ""
                    ]'>{{hasPhone ? Client.contact.phones[0] : 'Téléphone'}}</p>
            </div>
        </div>
        <div class="row px-3">
            <div class="col-12">
                <p
                    :class='[
                        "support-contact-infos-content-no-margin infos-perso px-3 pt-2",
                        dataLoading ? "field-loading" : ""
                    ]'>{{hasEmail ? Client.contact.email : 'E-mail'}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import Client from '../../models/client'

/**
 * This component displays detail about a loaner
 */
export default {
    props: {
        Title: String,
        Client: Client,
        dataLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasEmail: function() {
            return this.Client.contact && this.Client.contact.email;
        },
        hasPhone: function() {
            return this.Client.contact && this.Client.contact.phones && this.Client.contact.phones.length > 0;
        },
    }
}
</script>

<style scoped>

.infos-perso{
  background-color: #F2F2F2;
  color : #707070;
  border-radius: 20px;
  height:35px;
}

.arrow {  background: url('../../assets/images/go-cta-blue.svg'); width: 5px; height: 8px; display: inline-block; float: right; margin-top:5px; margin-right: 10px; transform: rotate(90deg); }

@keyframes loading {
  from {
    background-position: -800px 0
  }
  to {
    background-position: 800px 0
  }
}

.field-loading {
    animation: loading 2s infinite;
    background: linear-gradient(to right, #F2F2F2 8%, #ffffff 18%, #F2F2F2 33%);
    background-size: 800px 104px;
}
</style>
