<template>
    <div class="row">
        <div class="col-12 col-lg-6 col-xl-5 offset-xl-1">
            <div class="border border-color-vio block rounded shadow p-3 mb-5 bg-white rounded">
                <h4 class=" text-center content-blocks-title"><strong>{{Block_Info_Title}}</strong></h4>
                <hr />
                <BlckLoaner
                    :Title="SubScriber_Infos_Title"
                    :Client="Loaner"
                    :dataLoading="dataLoading" />
                <BlckLoaner v-if="CoLoaner"
                    :Title="CoSubscriber"
                    :Client="CoLoaner"
                    :dataLoading="dataLoading" />

                <p class="documents-rgpd-disclaimer text-center mt-3"><i>
                    {{Block_Info_Description}} <br />
                    {{Block_Info_Description_Following}}</i>
                </p>
            </div>
        </div>

        <div class="col-12 col-lg-6 col-xl-5">
            <div class="border border-color-vio block rounded shadow p-3 mb-5 bg-white rounded">
                <h4 class=" text-center content-blocks-title"><strong>{{Block_Preferences}}</strong>
                </h4>
                <hr class="mb-4" />
                <div :class='[
                    "row mb-3",
                    dataLoading ? "field-loading" : ""
                    ]'>
                    <div class="col-2 text-end">
                        <input class="form-check-input pref-moyen-checkbox"
                            type="checkbox" name="flexRadioDefault" id="flexRadioDefault1"
                            :value="acceptSms_Value" v-model="checkedComPref">
                    </div>
                    <div class="col-10 text-start">
                        <label class="pref-moyen-com" for="flexRadioDefault1">{{Preferences_SMS}}</label>
                    </div>
                </div>
                <div :class='[
                    "row mb-3",
                    dataLoading ? "field-loading" : ""
                    ]'>
                    <div class="col-2 text-end">
                        <input class="form-check-input pref-moyen-checkbox"
                            type="checkbox" name="flexRadioDefault" id="flexRadioDefault2"
                            :value="acceptEmailing_Value" v-model="checkedComPref">
                    </div>
                    <div class="col-10 text-start">
                        <label class="pref-moyen-com" for="flexRadioDefault2">{{Preferences_Email}}</label>
                    </div>
                </div>
                <div :class='[
                    "row mb-3",
                    dataLoading ? "field-loading" : ""
                    ]'>
                    <div class="col-2 text-end">
                        <input class="form-check-input pref-moyen-checkbox"
                            type="checkbox" name="flexRadioDefault" id="flexRadioDefault1"
                            :value="acceptPartners_Value" v-model="checkedComPref">
                    </div>
                    <div class="col-10 text-start">
                        <label class="pref-moyen-com" for="flexRadioDefault1">{{Preferences_Partners}}</label>
                    </div>
                </div>
                <div>
                    <button type="button" class="button-mini-warning-cus"
                        @click="updateCommPref()">{{ Preferences_Submit }}
                      <div class="arrow-white"/>
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="text-center closeaccount">
                    <a href="#" @click="confirmDeleteAccount()" class="text-muted">{{Delete_Account}}</a>
                </div>
            </div>
        </div>
        <ConfirmDeleteAccountPopup />
    </div>
</template>

<script>
import { computed } from "vue";
import { mapActions, useStore } from "vuex";
import store from "@/store";
import emitter from '../../events/emitter.js';
import BlckLoaner from './BlckLoaner.vue';
import Client from '../../models/client';
import getProfileRequest from '../../services/graphql/requests/getProfileRequest';
import ConfirmDeleteAccountPopup from '../../components/Popups/ConfirmDeleteAccountPopup';

export default {
    components: {
        BlckLoaner,
        ConfirmDeleteAccountPopup
    },
    setup() {
        const store = useStore();

        const mainClient = computed(() => store.getters[`client/getMainClient`]);
        const coClient = computed(() => store.getters[`client/getCoClient`]);

        return {
            mainClient,
            coClient
        }
    },
    data() {
        return {
            Block_Com_Description: "Pour modifier vos choix, vous pouvez sans frais et à tout moment adresser votre demande par voie postale à : Starto - Services Données Personnelles - Espace P - 3 Route de Nancy 54840 Gondreville ou par courrier électronique à : dpo@ymanci.fr",
            Block_Info_Title : "INFORMATIONS PERSONNELLES",
            Block_Info_Description : "Vous souhaitez mettre à jour ces informations?",
            Block_Info_Description_Following : "Contactez votre conseiller.",
            SubScriber_Infos_Title : "Coordonnées emprunteur",
            CoSubscriber : "Coordonnées co-emprunteur",
            Loaner: new Client({}),
            CoLoaner: null,
            Block_Preferences : "MES MOYENS DE COMMUNICATION",
            Preferences_SMS : "J'accepte de recevoir des SMS sur les produits et services de Starto",
            Preferences_Email : "J'accepte de recevoir des mails sur les produits et services de Starto",
            Preferences_Phone : "J'accepte d'être contacté(e) par téléphone pour de la prospection commerciale",
            Preferences_Partners : "J'accepte de recevoir des offres personnalisées de la part des partenaires de Starto",
            Preferences_Submit : "Mettre à jour",
            Preferences_Updated : "Vos nouveaux choix ont été pris en compte",
            Delete_Account : "Supprimer mon espace client",
            checkedComPref : [],
            acceptEmailing_Value: 'email',
            acceptSms_Value: 'sms',
            acceptPartners_Value: 'partenaires',
            dataLoading: false
        };
    },
    methods:{
        ...mapActions('client', ['setClientContactAccept', 'setClientPartnersAccept']),
        getData() {
            // get client data
            getProfileRequest().then((data) => {
                if (data.client) {
                    this.whenMainClientChanges(data.client);
                    store.commit("setUserFullname", this.Loaner.getFullname());
                }
                if (data.dossier && data.dossier.coLoaner) {
                    this.whenCoClientChanges(data.dossier.coLoaner);
                }
            }).catch((reason) => {
                console.error(reason);
            }).finally(() => {
                this.dataLoading = false;
            });
            this.dataLoading = true;
        },
        /**
         * present confirmation popup to delete account
         */
        confirmDeleteAccount() {
            emitter.emit('show-confirm-delete-account');
        },
        /**
         * @param {Object} client
         */
        whenMainClientChanges(client) {
            this.Loaner = new Client(client);
            this.checkedComPref = [];
            if (this.Loaner.contact) {
                if (this.Loaner.contact.acceptEmailing) {
                    this.checkedComPref.push(this.acceptEmailing_Value);
                }
                if (this.Loaner.contact.acceptSms) {
                    this.checkedComPref.push(this.acceptSms_Value);
                }
                if (this.Loaner.contact.acceptPartners) {
                    this.checkedComPref.push(this.acceptPartners_Value);
                }
            }
        },
        /**
         * @param {Object} client
         */
        whenCoClientChanges(client) {
            const coClient = new Client(client);
            if (coClient && coClient.id) {
                this.CoLoaner = coClient;
            }
        },

        updateCommPref() {
            const acceptPartners = this.checkedComPref.includes(this.acceptPartners_Value);
            this.setClientPartnersAccept({acceptPartners: acceptPartners}).then();
            const acceptEmailing = this.checkedComPref.includes(this.acceptEmailing_Value);
            const acceptSms = this.checkedComPref.includes(this.acceptSms_Value);
            this.setClientContactAccept({acceptEmailing, acceptSms}).then(() => {alert(this.Preferences_Updated)});
        }
    },
    mounted() {
        if (this.mainClient) {
            this.whenMainClientChanges(this.mainClient);
            if (this.coClient) {
                this.whenCoClientChanges(this.coClient);
            }
        }
        this.getData();
    }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/colors.scss';
.pref-moyen-com{
  font-size: 15px;
}

.pref-moyen-checkbox{
  margin-top: 3px;
  background: #D0CCCC;
  border: 2px solid black;
}

.pref-moyen-checkbox:checked{
  background: $color-primary;
  border-color: #D0CCCC;
}

.documents-rgpd-disclaimer {
  font-size: 12px;
  color : #707070;
}

.closeaccount { font-size: 12px; }

@keyframes loading {
  from {
    background-position: -800px 0
  }
  to {
    background-position: 800px 0
  }
}

@keyframes loading-checkbox {
  from {
    background: #fff;
  }
  to {
    background: #D0CCCC;
  }
}

.field-loading {
    .text-start{
        animation: loading 2s infinite;
        background: linear-gradient(to right, #ffffff 8%, #F2F2F2 18%, #ffffff 33%);
        background-size: 800px 104px;
    }

    input[type=checkbox] {
        animation: loading-checkbox 1s infinite;
    }
}
</style>
