import { send } from '../builder/multiquery.client';
import { Query } from '../builder/query';
import { getInstance } from '../../../auth/authWrapper';
import { getClientQuery } from '../queries/client.queries';
import { getDossierCoLoanerQuery } from '../queries/dossier.queries';
import { getClientAuthorization } from '../../api/client/client.authorizations';
import { getDossierAuthorization } from '../../api/dossier/dossier.authorizations';
import store from '../../../store';
import { SET_MAIN_CLIENT, SET_CO_CLIENT } from '../../../store/mutations/client.types';

export default function getProfileRequest() {
    const clientId = getInstance().getUserClientId();
    const dossierId = getInstance().getUserDossierId();
    return send({
        queries: [
            new Query(getClientQuery(clientId, dossierId), 'client'),
            new Query(getDossierCoLoanerQuery(dossierId, clientId), 'dossier')
        ],
        authorizations: [
            getClientAuthorization(),
            getDossierAuthorization()
        ],
        headers: {
            // TODO: remove after merge of #3262
            'X-AUTH-ROLES': 'CLIENT_GET_ONE,DOSSIER_GET_ONE'
        },
        responseTransformer(response) {
            if (response.data.data.client) {
                store._modules.root._children.client.context.commit(SET_MAIN_CLIENT, response.data.data.client);
            }
            if (response.data.data.dossier && response.data.data.dossier.coLoaner) {
                store._modules.root._children.client.context.commit(SET_CO_CLIENT, response.data.data.dossier.coLoaner);
            }
        }
    });
}
